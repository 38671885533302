const API = {
  header: "headers?populate=logo",
  slogan: "slogans?populate=bg_img",
  innovative: "innovatives?populate=bg_img,img_2",
  field: "fields?populate=img",
  footer: "footers?populate=brands,sharpcake_icon",
  services: "services?populate=",
  technologiesCards: "technologies-cards?populate=img",
  servicesCards: "services-cards?populate=img",
  clients: "clients?populate=imgs",
  connectArea: "connect-areas",
  connect: "connects/",
};

export default API;
