import React from "react";
import Button from "../button/CommonButton";
import "./footer.scss";

export default function Footer(props) {
  const { headerData } = props;
  const { footerData } = props;

  const Headerlist = [
    {
      list_item: headerData?.list_item_1,
      list_item_btn: headerData?.list_item_1_btn,
    },
    {
      list_item: headerData?.list_item_2,
      list_item_btn: headerData?.list_item_2_btn,
    },
    {
      list_item: headerData?.list_item_3,
      list_item_btn: headerData?.list_item_3_btn,
    },
  ];

  const brands = footerData[0]?.attributes?.brands?.data;

  return (
    <>
      <div className="footer-flex-right custom-fluid-container">
        <div>
          <img
            src={headerData?.logo?.data?.attributes?.url}
            className="header-logo"
            alt="logo"
          />
        </div>
        <div className={"footer-list footer-list-margin"}>
          <ul className={"flex-right-full "}>
            {Headerlist?.map((item, index) => {
              return (
                <li key={index} className={"header-list-items"}>
                  <a href={item?.list_item_btn}>{item?.list_item}</a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="footer-btn">
          <a href="#Contact">
            <Button
              btnBgColor="full-bg-seconderyColor"
              btnText={"Reach Us"}
              btnWidth="btn-w1"
              btnFontSize="btn-font-1"
            />
          </a>
        </div>
      </div>

      <div className="custom-fluid-container tertiary-color">
        <h6 className="footer-title">{footerData[0]?.attributes?.title}</h6>
        <p className="footer-content">{footerData[0]?.attributes?.content}</p>
        {brands?.map((item, index) => {
          return (
            <img
              key={index}
              className="footer-brand"
              src={item?.attributes?.url}
              alt="footer-brands"
            />
          );
        })}
        <div className="flex-right-full">
          <div>
            <p className="footer-bottom-padding footer-content">
              {footerData[0]?.attributes?.Privacy_Policy}
            </p>
          </div>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.sharpcake.com/"
            >
              <img
                className="footer-SC-logo"
                width={42}
                src={
                  footerData[0]?.attributes?.sharpcake_icon?.data?.attributes
                    ?.url
                }
                alt="sharpcake-logo"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
