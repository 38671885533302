import React from 'react';
import './CommonButton.scss';


const CommonButton = ({ btnText, btnBgColor, onClick , btnWidth , btnFontSize }) => {
    return (
    <div>
        <button className={`common-button ${btnBgColor} ${btnFontSize} ${btnWidth}`} onClick={onClick}>
            {btnText}
        </button>
    </div>
    );
};
export default CommonButton;
