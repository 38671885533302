import React from 'react';
import './Input.scss';

const Input = ({labelText, inputCustom, type, placeholder, value, onChange }) => {
    return (
       <div className='input-item'>
            <div className='input-label-item'>
                <label className='input-label'>
                    {labelText}
                </label>
            </div>
            <input
                className={`${inputCustom} input-common`}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
       </div>
    );
};

export default Input;
