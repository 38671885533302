import React from 'react';
import './ContentsArea.scss';

export default function ContentsLeft({header , content, headerFontSize , contentFontSize, headerTextColor, contentTextColor ,contentAreaWidth ,contentMarginBottom}) {
    return (
        <div className='contentsLeft'>
            <div className={`${contentAreaWidth}`}>
                <h2 className={`${headerTextColor} ${headerFontSize}`}>
                    {header}
                </h2>
                <p  className={` ${contentTextColor} ${contentFontSize} ${contentMarginBottom}`}>
                    {content}
                </p>
            </div>
        </div>
    );
}

