
import React from "react";
import './Card.scss';

export default function Card({imgLink, cardHeaderMargin, cardHidden, cardImgWidth, cardTitle, cardContent, borderBottom , borderRight , borderTop}) {
    return (
            <div className={`card-item card-padding ${cardHidden} ${borderBottom} ${borderRight} ${borderTop}`}>
                <img className={`${cardImgWidth}`} src={imgLink} alt="card-img"/>
                <h6 className={`card-header ${cardHeaderMargin}`}>
                    {cardTitle}
                </h6>
                <p className="card-content">
                    {cardContent}
                </p>
            </div>
    );
}

