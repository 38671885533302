import React from "react";
import Card from "./Card";
import "./Card.scss";

const ClientsCards = ({ clientsData }) => {
  const imgList = clientsData[0]?.attributes?.imgs?.data;

  return (
    <div id="Clients" className="contentsArea-center client-area">
      <h3 className="contentsArea-center H5 primery-color">
        {clientsData[0]?.attributes?.title}
      </h3>
      <p className="P2 fifth-color contentsArea-center-content">
        {clientsData[0]?.attributes?.content}
      </p>
      <div className="clients-card-row">
        {imgList?.map((item, index) => {
          return (
            <div key={index} className="clients-card-col">
              <Card
                imgLink={item?.attributes?.url}
                cardImgWidth={"card-img-width"}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ClientsCards;
