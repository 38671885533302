import React, { useEffect, useState } from "react";
import axios from "axios";
import api from "../../utils/api";
import Constant from "../../Constant";
import "./Input.scss";
import Input from "./Input";
import Button from "../button/CommonButton";
import { validateEmail } from "../../utils/validateEmail";
import {
  toastConfigurationError,
  toastConfigurationSuccess,
} from "../../utils/toastConfiguration";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function InputArea({
  inputTitle,
  inputContent,
  connectAreaData,
}) {
  const notify = () => toast("Wow so easy !");

  const [name, setName] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [error] = React.useState("");

  const nameChanged = (event) => {
    setName(event.target.value);
  };
  const companyChanged = (event) => {
    setCompany(event.target.value);
  };
  const emailChanged = (event) => {
    setEmail(event.target.value);
  };
  const roleChanged = (event) => {
    setRole(event.target.value);
  };
  const messageChanged = (event) => {
    setMessage(event.target.value);
  };
  const showErrorToast = (_message) => {
    toast.error(_message, toastConfigurationError);
  };
  const showSuccessToast = (_message) => {
    toast.success(_message, toastConfigurationSuccess);
  };

  const Send = () => {
    if (name.length < 2) {
      showErrorToast("Please enter your name!");
      return;
    }

    if (email.length === 0) {
      showErrorToast("Please Enter Email!");
      return;
    }

    if (company.length < 2) {
      showErrorToast("Please enter your company!");
      return;
    }

    if (role.length < 2) {
      showErrorToast("Please enter your role!");
      return;
    }

    if (!validateEmail(email)) {
      showErrorToast("Please Enter Valid Email!");
      return;
    }

    if (message.length < 10) {
      showErrorToast("Please enter your message!");
      return;
    }

    axios
      .post(
        `https://xtgm7zy6w3hi7v4ozzxyudzzuq0rhzwj.lambda-url.us-east-1.on.aws/`,
        {
          name: name,
          company: company,
          role: role,
          email: email,
          message: message,
          companyName: "Vobio",
          formName: "İletişim",
        }
      )
      .then(() => {
        setName("");
        setCompany("");
        setRole("");
        setEmail("");
        setMessage("");
        showSuccessToast("Success Message!");
      })
      .catch((e) => {
        alert("error");
      });
  };

  return (
    <div
      id="Contact"
      className="input-container custom-fluid-container global-padding-2"
    >
      <div className="contentsArea-center">
        <div className="input-text-area">
          <h5 className="contentsArea-center H5 primery-color">{inputTitle}</h5>
          <p className="P2 fifth-color contentsArea-center-content ">
            {inputContent}
          </p>
        </div>
        <div className="input-items-container">
          <div className="input-items-section">
            <Input
              labelText={connectAreaData[0]?.attributes?.label_1}
              inputType={"input"}
              id={"nameid-1"}
              name="Name"
              value={name}
              onChange={nameChanged}
              placeholder={"Your Name"}
              data-rule-required="true"
              data-msg-required="Required"
            />
            <Input
              labelText={connectAreaData[0]?.attributes?.label_2}
              type="email"
              id="email"
              name="Email"
              value={email}
              onChange={emailChanged}
              placeholder={"your@email.com"}
              data-rule-required="true"
              data-msg-required="Required"
              data-rule-email="true"
              data-msg-email="Invalid email address"
            />
          </div>
          <div className="input-items-section">
            <Input
              labelText={connectAreaData[0]?.attributes?.label_3}
              type="text"
              id="company"
              name="Company"
              value={company}
              onChange={companyChanged}
              placeholder={"Your Company"}
              data-rule-required="true"
              data-msg-required="Required"
            />
            <Input
              labelText={connectAreaData[0]?.attributes?.label_4}
              type="text"
              id="role"
              name="Role"
              value={role}
              onChange={roleChanged}
              placeholder={"Your Role"}
              data-rule-required="true"
              data-msg-required="Required"
            />
          </div>
          <div className="input-items-section input-items-section-msg">
            <Input
              labelText={connectAreaData[0]?.attributes?.label_5}
              id="message"
              name="Note"
              onChange={messageChanged}
              value={message}
              placeholder={"Start typing here..."}
              data-rule-required="true"
              data-msg-required="Required"
            />
          </div>
          <div className="input-btn">
            <ToastContainer />
            <Button
              btnBgColor="full-bg-seconderyColor"
              btnText={"Send"}
              btnWidth="btn-w2"
              btnFontSize="btn-font-2"
              onClick={Send}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
