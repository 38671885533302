import React, { useEffect, useState } from "react";
import axios from "axios";
import Constant from "./Constant";
import API from "./utils/api";
import Button from "./components/button/CommonButton";
import Header from "./components/header/Header";
import ContentsLeft from "./components/contentsArea/ContentsLeft";
import FieldArea from "./components/fieldArea/FieldArea";
import ServicesCards from "./components/card/ServicesCards";
import "bootstrap/dist/css/bootstrap.min.css";
import "./main.scss";
import ClientsCards from "./components/card/ClientsCards";
import InputArea from "./components/input/InputArea";
import Footer from "./components/footer/Footer";
import imgLink from "./images/arrow_right.png";

function App() {
  const [headerData, setHeaderData] = useState("");
  const [sloganData, setSloganData] = useState("");
  const [innovativeData, setInnovativeData] = useState("");
  const [fieldData, setFieldData] = useState("");
  const [serviceData, setServiceData] = useState("");
  const [servicesCardsData, setServicesCardsData] = useState([]);
  const [technologiesCardsData, setTechnologiesCardsData] = useState([]);
  const [clientsData, setClientsData] = useState("");
  const [connectAreaData, setConnectAreaData] = useState("");
  const [footerData, setFooterData] = useState("");

  useEffect(() => {
    HeaderDataRequest();
    SloganDataRequest();
    InnovativeDataRequest();
    FieldDataRequest();
    FooterDataRequest();
    ServiceDataRequest();
    ServicesCardsDataRequest();
    TechnologiesCardsDataRequest();
    ClientsDataRequest();
    ConnectAreaDataRequest();
  }, []);

  const HeaderDataRequest = async () => {
    try {
      const HeaderData = await axios.get(`${Constant.API}${API.header}`);
      setHeaderData(HeaderData.data.data[0]?.attributes);
    } catch (error) {
      console.log("AXIOS ERROR", error);
    }
  };

  const SloganDataRequest = async () => {
    try {
      const SloganData = await axios.get(`${Constant.API}${API.slogan}`);
      setSloganData(SloganData.data.data[0]?.attributes);
    } catch (error) {
      console.log("AXIOS ERROR", error);
    }
  };

  const InnovativeDataRequest = async () => {
    try {
      const InnovativeData = await axios.get(`${Constant.API}${API.innovative}`);
      setInnovativeData(InnovativeData.data.data[0]?.attributes);
    } catch (error) {
      console.log("AXIOS ERROR", error);
    }
  };

  const FieldDataRequest = async () => {
    try {
      const FieldData = await axios.get(`${Constant.API}${API.field}`);
      setFieldData(FieldData.data.data);
    } catch (error) {
      console.log("AXIOS ERROR", error);
    }
  };

  const ServiceDataRequest = async () => {
    try {
      const ServiceData = await axios.get(`${Constant.API}${API.services}`);
      setServiceData(ServiceData.data.data);
    } catch (error) {
      console.log("AXIOS ERROR", error);
    }
  };

  const ServicesCardsDataRequest = async () => {
    try {
      const ServicesCardsData = await axios.get(`${Constant.API}${API.servicesCards}`);
      setServicesCardsData(ServicesCardsData.data.data);
    } catch (error) {
      console.log("AXIOS ERROR", error);
    }
  };

  const TechnologiesCardsDataRequest = async () => {
    try {
      const TechnologiesCardsData = await axios.get(`${Constant.API}${API.technologiesCards}`);
      setTechnologiesCardsData(TechnologiesCardsData.data.data);
    } catch (error) {
      console.log("AXIOS ERROR", error);
    }
  };

  const ClientsDataRequest = async () => {
    try {
      const ClientsData = await axios.get(`${Constant.API}${API.clients}`);
      setClientsData(ClientsData.data.data);
    } catch (error) {
      console.log("AXIOS ERROR", error);
    }
  };

  const ConnectAreaDataRequest = async () => {
    try {
      const ConnectAreaData = await axios.get(`${Constant.API}${API.connectArea}`);
      setConnectAreaData(ConnectAreaData.data.data);
    } catch (error) {
      console.log("AXIOS ERROR", error);
    }
  };

  const FooterDataRequest = async () => {
    try {
      const FooterData = await axios.get(`${Constant.API}${API.footer}`);
      setFooterData(FooterData.data.data);
    } catch (error) {
      console.log("AXIOS ERROR", error);
    }
  };

  return (
    <div className="full-width">
      <div className="full-bg-primaryColor scroll-header">
        <div className="header-container flex-right-full custom-fluid-container">
          <Header headerData={headerData} />
          <div className="header-btn">
            <a href="#Contact">
              <Button btnBgColor="full-bg-seconderyColor" btnText={headerData?.btn_text} btnWidth="btn-w1" btnFontSize="btn-font-1" />
            </a>
            <div>
              <a href="#Contact">
                <button className="header-button-arrow">
                  <img className="header-btn-img" src={imgLink} alt="header-btn-img" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <video autoPlay muted loop playsInline className="video-bg">
          <source src="/herovideo.mp4" type="video/mp4" />
        </video>
        <div className="custom-fluid-container global-padding relative">
          <ContentsLeft header={sloganData?.title} content={sloganData?.content} headerFontSize={"H1"} contentFontSize={"P1"} contentAreaWidth={"content-w4"} contentMarginBottom={"content-my-1"} headerTextColor={"tertiary-color"} contentTextColor={"tertiary-color"} />
          <a href="#Flexible">
            <Button btnBgColor="full-bg-seconderyColor" btnText={sloganData?.btn_text} btnWidth="btn-w2" btnFontSize="btn-font-2" />
          </a>
        </div>
      </div>
      <div className="full-bg-img full-bg-img-2">
        <div id="Flexible" className="custom-fluid-container global-padding">
          <ContentsLeft header={innovativeData?.title} content={innovativeData?.content} headerFontSize={"H2"} contentFontSize={"P1"} contentAreaWidth={"content-w1"} contentMarginBottom={"content-my-1"} headerTextColor={"primery-color"} contentTextColor={"fifth-color"} />
        </div>
        <img className="innovative-min-img" src="https://vobiocms.s3.amazonaws.com/innovative_min_img.png" alt="innovative-img-min" />
      </div>
      <FieldArea fieldData={fieldData} />
      <div className="full-bg-primaryColor">
        <ServicesCards serviceData={serviceData} servicesCardsData={servicesCardsData} technologiesCardsData={technologiesCardsData} />
      </div>
      <div className="clients-cards-area global-padding-2 full-bg-tertiaryColor custom-fluid-container">
        <ClientsCards clientsData={clientsData} />
      </div>
      <div className="full-bg-input">
        <InputArea connectAreaData={connectAreaData} inputHeaderMargin={""} inputTitle={connectAreaData[0]?.attributes?.title} inputContent={connectAreaData[0]?.attributes?.content} />
      </div>
      <div className="full-bg-primaryColor ">
        <Footer footerData={footerData} headerData={headerData} />
      </div>
    </div>
  );
}

export default App;
