import React, { useState } from "react";
import Card from "./Card";
import Button from "../button/CommonButton";
import "./Card.scss";

export default function ServicesCards({
  serviceData,
  servicesCardsData,
  technologiesCardsData,
}) {
  const [displayServices, setDisplayServices] = useState(true);
  const [displayTechnologies, setDisplayTechnologies] = useState(false);

  const handleServicesClick = () => {
    setDisplayServices(true);
    setDisplayTechnologies(false);
  };

  const handleTechnologiesClick = () => {
    setDisplayServices(false);
    setDisplayTechnologies(true);
  };

  return (
    <>
      <div
        id="Services"
        className="servicesArea contentsArea-center custom-fluid-container global-padding-2 "
      >
        <h3 className=" H5 tertiary-color">
          {serviceData[0]?.attributes?.title}
        </h3>
        <p className="P2 tertiary-color-opacity contentsArea-center-content">
          {serviceData[0]?.attributes?.content}
        </p>
        <div className="global-d-flex-center ST-btn-area">
          <Button
            btnBgColor={
              displayServices ? "full-bg-seconderyColor" : "services-pasive-btn"
            }
            btnText={serviceData[0]?.attributes?.btn_1}
            btnWidth="btn-w3"
            btnFontSize="btn-font-3"
            onClick={handleServicesClick}
          />
          <Button
            btnBgColor={
              displayTechnologies
                ? "full-bg-seconderyColor"
                : "services-pasive-btn"
            }
            btnText={serviceData[0]?.attributes?.btn_2}
            btnWidth="btn-w3"
            btnFontSize="btn-font-3"
            onClick={handleTechnologiesClick}
          />
        </div>

        <div
          className={
            displayServices && !displayTechnologies
              ? "services-btn-area"
              : "hide"
          }
        >
          <div
            className={
              "services-btn-area container-custom-fluid cards-scroll-max-width"
            }
          >
            <div className="services-flex-right-full cards-scroll-container">
              {servicesCardsData?.slice(0, 4).map((item, index) => {
                return (
                  <span
                    className="services-bottom services-card-item"
                    key={index}
                  >
                    <Card
                      imgLink={item?.attributes?.img?.data?.attributes?.url}
                      cardImgWidth={"card-img-width-services"}
                      cardTitle={item?.attributes?.title}
                      cardHeaderMargin={"content-my-3"}
                      cardContent={item?.attributes?.content}
                    />
                  </span>
                );
              })}
              {servicesCardsData?.slice(4).map((item, index) => {
                return (
                  <span className="services-card-item" key={index}>
                    <Card
                      imgLink={item?.attributes?.img?.data?.attributes?.url}
                      cardImgWidth={"card-img-width-services"}
                      cardHidden={"cards-hidden"}
                      cardTitle={item?.attributes?.title}
                      cardHeaderMargin={"content-my-3"}
                      cardContent={item?.attributes?.content}
                    />
                  </span>
                );
              })}
            </div>
            <div className="services-flex-right-full cards-bottom-area cards-scroll-container">
              {servicesCardsData?.slice(4).map((item, index) => {
                return (
                  <span className="services-card-item" key={index}>
                    <Card
                      imgLink={item?.attributes?.img?.data?.attributes?.url}
                      cardImgWidth={"card-img-width-services"}
                      cardHidden={"cards-hidden-2"}
                      cardTitle={item?.attributes?.title}
                      cardHeaderMargin={"content-my-3"}
                      cardContent={item?.attributes?.content}
                    />
                  </span>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className={
            !displayServices && displayTechnologies
              ? "technologies-btn-area"
              : "hide"
          }
        >
          <div className={"container-custom-fluid cards-scroll-max-width"}>
            <div className="techno-flex-right-full cards-scroll-container">
              {technologiesCardsData?.slice(0, 3).map((item, index) => {
                return (
                  <span className="techno-card-item techno-bottom" key={index}>
                    <Card
                      imgLink={item?.attributes?.img?.data?.attributes?.url}
                      cardImgWidth={"card-img-width-services"}
                      cardTitle={item?.attributes?.title}
                      cardHeaderMargin={"content-my-3"}
                      cardContent={item?.attributes?.content}
                    />
                  </span>
                );
              })}
              {technologiesCardsData?.slice(3).map((item, index) => {
                return (
                  <span className="techno-card-item" key={index}>
                    <Card
                      imgLink={item?.attributes?.img?.data?.attributes?.url}
                      cardImgWidth={"card-img-width-services"}
                      cardHidden={"cards-hidden"}
                      cardTitle={item?.attributes?.title}
                      cardHeaderMargin={"content-my-3"}
                      cardContent={item?.attributes?.content}
                    />
                  </span>
                );
              })}
            </div>
            <div className="techno-flex-right-full cards-bottom-area cards-scroll-container">
              {technologiesCardsData?.slice(3).map((item, index) => {
                return (
                  <span className="techno-card-item" key={index}>
                    <Card
                      imgLink={item?.attributes?.img?.data?.attributes?.url}
                      cardImgWidth={"card-img-width-services"}
                      cardHidden={"cards-hidden-2"}
                      cardTitle={item?.attributes?.title}
                      cardHeaderMargin={"content-my-3"}
                      cardContent={item?.attributes?.content}
                    />
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
