import React, { useEffect, useState } from "react";
import "./Header.scss";

export default function Header(props) {
  const { headerData } = props;

  const Headerlist = [
    {
      list_item: headerData?.list_item_1,
      list_item_btn: headerData?.list_item_1_btn,
    },
    {
      list_item: headerData?.list_item_2,
      list_item_btn: headerData?.list_item_2_btn,
    },
    {
      list_item: headerData?.list_item_3,
      list_item_btn: headerData?.list_item_3_btn,
    },
  ];

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollY > 10000) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <>
          <a href="#">
            <img src={headerData?.logo?.data?.attributes?.url} className="header-logo" alt="logo" />
          </a>
          <div className={"header-list "}>
            <ul className={"flex-right-full"}>
              {Headerlist?.map((item, index) => {
                return (
                  <li key={index} className={"header-list-items"}>
                    <a href={item?.list_item_btn}>{item?.list_item}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </>
  );
}
