import React from "react";
import "./FieldArea.scss";

const FieldArea = ({ fieldData }) => {
  const Fields = [
    {
      className: "item0",
      header: fieldData[0]?.attributes?.title,
      content: fieldData[0]?.attributes?.content,
    },
    {
      className: "item1",
      header: fieldData[1]?.attributes?.title,
      content: fieldData[1]?.attributes?.content,
      logo: fieldData[1]?.attributes?.img?.data?.attributes?.url,
    },
    {
      className: "item2",
      header: fieldData[2]?.attributes?.title,
      content: fieldData[2]?.attributes?.content,
      logo: fieldData[2]?.attributes?.img?.data?.attributes?.url,
    },
    {
      className: "item3",
      header: fieldData[3]?.attributes?.title,
      content: fieldData[3]?.attributes?.content,
      logo: fieldData[3]?.attributes?.img?.data?.attributes?.url,
    },
    {
      className: "item4",
      header: fieldData[4]?.attributes?.title,
      content: fieldData[4]?.attributes?.content,
      logo: fieldData[4]?.attributes?.img?.data?.attributes?.url,
    },
  ];

  return (
    <>
      <div id="AboutUs" className="field-container">
        <div className="field-flex-area">
          {Fields?.map((item, index) => {
            return (
              <div key={index} className={item?.className}>
                <img src={item?.logo} alt="field-logo" />
                <h2 className={`headerStyle`}>{item?.header}</h2>
                <p className={`contentStyle`}>{item?.content}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default FieldArea;
